import React from 'react';
import Layout from '../components/layout';
import { BaseProps } from '../typings/types';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';

export const PureNotFoundPage: React.FC = () => (
  <Layout title="Not Found">
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

const NotFoundPage: React.FC<BaseProps> = ({ location: { pathname } }) => {
  useAuthRouteProtection(pathname);
  return <PureNotFoundPage />;
};

export default NotFoundPage;
